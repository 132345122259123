const config = {
  rootProjectId: "root",
  uiBucketName: "gainprod.c.retter.io",
  appUrl: "https://api.gainprod.retter.io/",
  cosUrl: "api.gainprod.retter.io",
  version: "2.1.14",
  captchaKey: "6Lc0n34qAAAAAIfTMkddL79C1IlgVhr28yOXPWjQ",
  stage: "PROD"
}
export default config

